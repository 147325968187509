import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'grommet';
import usStates from '../lib/usStates';

const FormattedContactAddress = ({ contact = {}, ...rest }) => {
  const {
    field_address_string,
    field_state_string,
    field_zip_string,
    field_city_string,
  } = contact;
  if (!contact) return null;
  const state = usStates.find((x) => x.name === field_state_string);
  return (
    <Text {...rest}>
      {field_address_string}, {field_city_string}
      {state && state.abbreviation === 'DC' ? '' : ', '}
      {state
        ? state.abbreviation === 'DC'
          ? ' '
          : state.abbreviation
        : field_state_string}{' '}
      {field_zip_string}
    </Text>
  );
};

FormattedContactAddress.propTypes = {
  contact: PropTypes.object.isRequired,
};

export default memo(FormattedContactAddress);
