import React from 'react';
import PropTypes from 'prop-types';

import StockistList from '../StockistList';

const DynamicStockistList = ({ blok }) => <StockistList {...blok} />;

DynamicStockistList.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default DynamicStockistList;
