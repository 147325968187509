import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, Text, Heading } from 'grommet';

import deNodify from '../lib/deNodify';
import { groupBy, sortBy } from '../lib/nodash';
import useAdjustedHeadingSize from './useAdjustedHeadingSize';
import FormattedContactAddress from './FormattedContactAddress';
import SmartLink from './SmartLink';
import useFillOptions from './useFillOptions';

function StockistList({
  itemHorizontalPad = 'xlarge',
  itemVerticalPad = 'large',
  contactType = 'all',
  fill,
  ...rest
}) {
  const { allStoryblokEntry } = useStaticQuery(graphql`
    query AllStockists {
      allStoryblokEntry(filter: { full_slug: { glob: "stockists/**" } }) {
        edges {
          node {
            id
            field_address_string
            field_name_string
            field_state_string
            field_zip_string
            field_city_string
            field_url_string
            field_email_string
            field_phone_string
            field_contactType_string
            internalId
          }
        }
      }
    }
  `);
  const contacts = useMemo(
    () =>
      deNodify(allStoryblokEntry).filter(
        (x) =>
          contactType === 'all' || x.field_contactType_string === contactType
      ),
    [allStoryblokEntry, contactType]
  );
  const byState = useMemo(
    () => groupBy((x) => x.field_state_string, contacts),
    [contacts]
  );
  const stateHeadingStyle = useAdjustedHeadingSize(5);
  const { fillValues } = useFillOptions(fill);

  return (
    <Box fill={fillValues} {...rest}>
      {Object.keys(byState)
        .sort()
        .map((state) => (
          <Box
            key={state}
            gap="xsmall"
            pad={{ vertical: itemVerticalPad, horizontal: itemHorizontalPad }}
          >
            <Heading level={2} style={stateHeadingStyle}>
              {state}
            </Heading>
            <Box gap="medium">
              {sortBy('field_name_string', byState[state]).map((stockist) => (
                <Box gap="xsmall" key={stockist.id}>
                  <Heading level={3} style={{ textTransform: 'none' }}>
                    {stockist.field_name_string}
                  </Heading>
                  <FormattedContactAddress contact={stockist} size="small" />
                  {(stockist.field_email_string ||
                    stockist.field_phone_string) && (
                    <Text>
                      <SmartLink to={`mailto:${stockist.field_email_string}`}>
                        {stockist.field_email_string}
                      </SmartLink>{' '}
                      &bull;{' '}
                      <SmartLink to={`tel:${stockist.field_phone_string}`}>
                        {stockist.field_phone_string}
                      </SmartLink>
                    </Text>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        ))}
    </Box>
  );
}

StockistList.propTypes = {
  itemHorizontalPad: PropTypes.string,
  itemVerticalPad: PropTypes.string,
  contactType: PropTypes.oneOf(['stockist', 'retailPartner', 'other']),
  fill: PropTypes.object,
};

export default memo(StockistList);
